// App.js
import React from 'react';
import MapComponent from './MapComponent';
import ErrorBoundary from './ErrorBoundary';
function App() {
  return (
    <div className="App">
       <ErrorBoundary>
      <MapComponent />
      </ErrorBoundary>
    </div>
  );
}

export default App;